<template>
  <b-row>
    <b-col cols="12">
      <echart-area />
    </b-col>
     <b-col cols="12">
      <echart-payment-area />
    </b-col>
    
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import EchartArea from './EchartArea.vue'
import EchartPaymentArea from './EchartPaymentArea.vue'

export default {
  components: {
    BRow,
    BCol,

    EchartArea,
    EchartPaymentArea
  },
}
</script>
